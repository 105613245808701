<template>
  <div class="group-introduction">
    <Header @link-jump="jump"></Header>
    <!-- <button @click="jump" style="color: #000; margin-top: 120px">跳转</button> -->
    <!-- banner -->
    <div class="banner img_content_pub">
      <img v-lazy="require('../assets/imgs/banner.png')" alt="" />
      <!-- <video width="100%" height="100%" controls autoplay>
        <source src="https://old-home.qiyegl.cn//files/2022/03/31/1648720302174%E8%80%81%E5%AE%B6%E6%B2%B3%E5%8D%97.mp4" type="video/mp4" />
      </video> -->
    </div>
    <!-- 品牌文化 -->
    <div id="brand" class="introduction">
      <div class="introduction_main">
        <div class="title">
          <img src="../assets/imgs/title_bg_left.png" alt="" />
          <span>品牌文化</span>
          <img src="../assets/imgs/title_bg_right.png" alt="" />
        </div>
        <div>
          <p class="detail">
            老家河南酒业集团有限公司，成立于2021年10月15日。注册资本壹仟万圆整，由河南省文旅投资集团旗下的河南省中视新科文化产业有限公司、河南日报报业集团旗下河南大河网络传媒集团有限公司的子公司大河传媒有限公司、郑州时代天鉴商贸有限公司合资成立，以市场为导向，做国资控股、民营主导的市场型合资企业。
          </p>
          <p class="detail">
            “老家河南”作为河南省的外宣传播、旅游形象、文化归属、情感认同之全维度融于一体的超级IP，具备巨大的品牌价值和商业潜能。作为河南省唯一国资控股的综合性酒业集团，老家河南酒业集团肩负弘扬中原文化使命，全力推动豫酒振兴；依托不断出圈的河南文化资源与文旅产业发展大势，以厚重文化与国潮风格为脉络，不断打造更符合河南消费者和市场的优质好酒。
          </p>
          <p class="detail">
            老家河南酒业集团主营业务为酒类经营（白酒批发、团购、零售）；现有产品为“老家河南·豫鉴”、“老家河南·豫品”系列53°酱香型白酒。“豫鉴”、“豫品”系列酱香型白酒选用优质河南小麦、正宗红缨子高粱及位于赤水河谷茅台镇7.5平方公里酱酒核心产区的水源为原料，严格遵循大曲酱香白酒的古法酿造工艺，生产周期为一年，经过两次投料，九次蒸煮，八次发酵，七次取酒，历时五年以上的窖藏，最后老酒调味而成。具有酱香突出、优雅细腻、酒体醇和绵柔、口感醇厚、回味悠长、空杯留香持久的特点。未来将继续拓展浓香、清香等其他香型产品。
          </p>
        </div>
        <div>
          <div class="btn_group">
            <div class="btn" :class="active == 0 ? 'active' : ''" @click="onChange(0)">
              国资控股
            </div>
            <div class="btn" :class="active == 1 ? 'active' : ''" @click="onChange(1)">
              百亿品牌
            </div>
            <div class="btn" :class="active == 2 ? 'active' : ''" @click="onChange(2)">
              超级IP
            </div>
            <div class="btn" :class="active == 3 ? 'active' : ''" @click="onChange(3)">
              河南名片
            </div>
            <div class="btn" :class="active == 4 ? 'active' : ''" @click="onChange(4)">
              老家礼物
            </div>
          </div>
          <div class="tab" v-if="active == 0">
            <div class="tab_img img_content_pub">
              <img v-lazy="require('../assets/imgs/gzkg.jpeg')" alt="" />
            </div>
            <div>
              <p class="context">
                老家河南酒业集团有限公司，由河南省文旅投资集团旗下的河南省中视新科文化产业有限公司联合河南日报报业集团旗下大河传媒有限公司共同合资成立，民营企业参股，以市场为导向，做国资控股、民营主导的市场型合资企业。老家河南系列商标转由合资公司持有，并独家运营使用。
              </p>
            </div>
          </div>
          <div class="tab" v-if="active == 1">
            <img v-lazy="require('../assets/imgs/bypp.jpg')" alt="" />
            <div>
              <p class="context">
                2012年老家河南作为商标由河南省文旅投资集团旗下的河南省中视新科文化产业有限公司进行了商业化运作。“老家河南”的提出，契合河南"旅游立省"、"文化强省"的经济发展战略与因势利导的宗旨。老家河南作为河南省整体对外的文化旅游品牌与好客山东、七彩云南、多彩贵州一样，引领省域文化向外扩展。
              </p>
              <p class="context">
                2017年央视老家河南品牌估值60亿元，省政府每年斥巨资在央广媒体、互联网媒体、政府对外会议、展览，省内核心高铁机场进行全方位的宣传打造。在河南省委省政府的高度重视下，更是将老家河南品牌建设列为省工作重点议题，也纳入我省对外形象宣传工程中，全省重要经贸、会展、体育等其他活动中也统一使用“老家河南”标识。2020年实施的《河南省旅游条例》中做出规定，要求县级以上人民政府应当共同打造“老家河南”品牌。2021年河南省第十一届党代会报告中更强调，全面推进文旅文创融合战略，打造中华文化传承创新中心，持续打造老家河南等系列品牌。
              </p>
            </div>
          </div>
          <div class="tab" v-if="active == 2">
            <img v-lazy="require('../assets/imgs/cjip.jpg')" alt="" />
            <div>
              <p class="context">
                “老家河南”品牌是以华夏文化之根作为品牌规划核心，将河南的历史沉淀、乡情风貌、旅游资源、发展态势在老家品牌上得到具体的体现。它通过挖掘、树立华夏文明传统文化，通过传承创新的现代产业化发展，使华夏文明、中原文化和“老家”情感通过人文景观、传统民宿、地方特产等产业成为可以感受、品尝、触摸、体验的立体存在，将河南打造成全球华人的“精神故乡”。“老家河南”作为河南省的外宣传播、旅游形象、文化归属、情感认同之全维度融于一体的超级IP，具备巨大的品牌价值和商业潜能。
              </p>
            </div>
          </div>
          <div class="tab" v-if="active == 3">
            <img v-lazy="require('../assets/imgs/hnmp.jpeg')" alt="" />
            <div>
              <p class="context">
                老家河南酒业集团依托河南文旅集团强大优势资源，对接各地文旅文投集团，以老家河南品牌势能带动文旅文创产业，实施文旅文创融合战略。将河南每座城市特有的文化故事，名城古迹，风俗民情与酒完美结合，向传统文化挖掘内容，在“诠释传统文化”与“年轻化表达”之间寻找理想平衡点，推动“流量”变“留量”。老家河南酒业集团持续在文旅文创白酒细分领域的产业体系打造，发展富有特色的全链条业态，壮大以创意为内核的文化名酒产业，使老家河南酒成为河南文化宣传的一张靓丽名片。
              </p>
            </div>
          </div>
          <div class="tab" v-if="active == 4">
            <img v-lazy="require('../assets/imgs/ljlw.jpeg')" alt="" />
            <div>
              <p class="context">
                为一亿家乡父老包括在外的河南人打造一瓶美酒，是老家河南酒业集团的初衷。通过这一瓶美酒将厚重、博大的河南文化传递出去，让这瓶酒成为“优秀河南文化的传播者”则是老家河南酒业集团的情怀追求。
              </p>
              <p class="context">
                “老家河南”酒以史为媒，以文化为基础，在产品设计、包装上进行创新，旨在更大范围、更深层次的引起共振和认同。强化“中”的超级符号表达：瓶身选取了河南最具代表性的几个名胜风光，黄帝故里、龙门石窟、少林寺、黄河、二七塔，用国潮插画的风格进行了绘制，通过一幅完整连贯的插画表现，让“中”字符产生远看有形，近看有景的效果；瓶盖辅以河南博物院的镇院之宝“四神云气图”作为纹饰，用这绝世的壁画珍品图形，揭开河南厚重的远古历史。展现河南形象，打造河南名片。现在，老家河南酒正在激活千年历史文化资源宝藏，用匠心致敬亿万河南人。
              </p>
            </div>
          </div>
        </div>
        <div>
          <div class="sub_title">老家河南 一亿人的待客酒</div>
          <p class="detail">
            故乡情结、老家情感自古以来是中国人心中最朴素的一种感情，也是最容易产生共鸣的感情。现代社会，人口流动、迁徙更为频繁，走得越远，对故乡的眷恋就越深；离开故土越久，对老家的感情就越发地深入心底。因此挖掘“老家”文化更具价值。
          </p>
          <p class="detail">
            河南省是国内久负盛名的白酒消费大省，白酒市场容量高达600亿。正是基于河南白酒市场规模大、白酒消费人群多的特点，河南省成为了各大白酒品牌的必争之地。老家河南酒作为豫酒振兴的扛旗者，希望带给消费者有温度的情感共鸣，也能倡导白酒行业的价值回归。
          </p>
          <p class="detail">
            2021年，老家河南酒业集团整合各方优势资源，以河南酒业扛旗手之姿态横空出世。作为我省唯一国资控股的综合性酒业集团，肩负弘扬中原文化使命，全力推动豫酒振兴，依托不断出圈的河南文化资源与文旅产业发展大势，以厚重文化与国潮风格为脉络，不断打造更符合河南消费者和市场的优质好酒。
          </p>
          <p class="detail">
            老家河南酒业集团坚决怀抱开放的心态，坚持做文创酒平台型企业，为消费者喝的每一瓶酒赋予文化内涵，做一亿河南人的待客酒！
          </p>
        </div>
      </div>
    </div>
    <!-- 发展历程 -->
    <div id="devHistory" class="section development">
      <div class="dev_title">老家河南三个战略阶段</div>
      <div class="dev_list">
        <div class="dev_list_content">
          <div
            class="list_item"
            :class="devActive == 0 ? 'active' : ''"
            @click="onChangeDev(0)"
          >
            <div class="item_top">
              <img src="../assets/imgs/one.png" alt="" />
            </div>
            <div class="item_bottom">
              <div class="item_title">文化战略阶段</div>
              <div class="time">2009-2012</div>
            </div>
          </div>
          <div
            class="list_item line"
            :class="devActive == 1 ? 'active' : ''"
            @click="onChangeDev(1)"
          >
            <div class="item_top">
              <img src="../assets/imgs/tow.png" alt="" />
            </div>
            <div class="item_bottom">
              <div class="item_title">品牌战略阶段</div>
              <div class="time">2012-2019</div>
            </div>
          </div>
          <div
            class="list_item"
            :class="devActive == 2 ? 'active' : ''"
            @click="onChangeDev(2)"
          >
            <div class="item_top">
              <img src="../assets/imgs/three.png" alt="" />
            </div>
            <div class="item_bottom">
              <div class="item_title">生态战略阶段</div>
              <div class="time">2019-至今</div>
            </div>
          </div>
        </div>
        <div class="time_line">
          <div class="line_item">
            <img
              class="active"
              v-if="devActive == 0"
              src="../assets/imgs/time_dot_active.png"
              alt=""
            />
            <!-- <img class="normal" v-else src="../assets/imgs/time_dot.png" alt="" /> -->
            <span v-else class="normal"></span>
          </div>
          <div class="line_item">
            <img
              class="active"
              v-if="devActive == 1"
              src="../assets/imgs/time_dot_active.png"
              alt=""
            />
            <span v-else class="normal"></span>
          </div>
          <div class="line_item">
            <img
              class="active"
              v-if="devActive == 2"
              src="../assets/imgs/time_dot_active.png"
              alt=""
            />
            <span v-else class="normal"></span>
          </div>
        </div>
      </div>
      <div v-for="(swiperItem, swiperIndex) in developmentList" :key="swiperIndex">
        <div
          class="development-swiper"
          :class="'development-swiper-' + swiperIndex"
          v-if="devActive == swiperIndex"
        >
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in swiperItem" :key="index">
              <div class="development_item">
                <div class="dev_slide_img img_content_pub">
                  <img v-lazy="item.img" alt="" />
                </div>

                <div class="detail">
                  <div class="sub_title">{{item.year}}{{ item.title }}</div>
                  <div class="line"></div>
                  <p class="content_text">
                    {{ item.desc }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- 如果需要分页器 -->
          <div class="swiper-pagination"></div>

          <!-- 如果需要导航按钮 -->
          <div
            v-if="devActive == 0 || (devActive != 0 && !reachStart)"
            class="swiper-button-prev"
          ></div>
          <div v-else class="swiper_button_prev" @click="changeDevActive1"></div>
          <div v-if="!reachEnd" class="swiper-button-next"></div>
          <div v-else class="swiper_button_next" @click="changeDevActive"></div>
        </div>
      </div>
    </div>
    <!-- 酿造工艺 -->
    <div id="brewingTechnology" class="section development workmanship">
      <div class="dev_title">酿造工艺</div>
      <div class="dev_list">
        <div class="workmanship_tabs">
          <div
            class="tab"
            :class="workmanshipActive == 0 ? 'active' : ''"
            @click="workmanshipChange(0)"
          >
            酿酒原料
          </div>
          <div
            class="tab"
            :class="workmanshipActive == 1 ? 'active' : ''"
            @click="workmanshipChange(1)"
          >
            酿造工艺
          </div>
        </div>
        <div class="time_line">
          <div class="line_item">
            <img
              class="active"
              v-if="workmanshipActive == 0"
              src="../assets/introduction/introduction_active.png"
              alt=""
            />
            <img
              class="normal"
              v-else
              src="../assets/introduction/introduction_normal.png"
              alt=""
            />
            <!-- <span v-else class="normal"></span> -->
          </div>
          <div class="line_item">
            <img
              class="active"
              v-if="workmanshipActive == 1"
              src="../assets/introduction/introduction_active.png"
              alt=""
            />
            <img
              class="normal"
              v-else
              src="../assets/introduction/introduction_normal.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div v-for="(swiperItem, swiperIndex) in workmanshipList" :key="swiperIndex">
        <div
          class="brewing-technology-swiper"
          :class="'brewing-technology-swiper-' + swiperIndex"
          v-if="workmanshipActive == swiperIndex"
        >
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in swiperItem" :key="index">
              <div class="development_item">
                <div class="detail">
                  <div class="sub_title">{{ item.title }}</div>
                  <div class="line"></div>
                  <p
                    class="content_text"
                    v-for="(dataItem, dataIndex) in item.contentList"
                  >
                    {{ dataItem }}
                  </p>
                </div>
                <div class="dev_slide_img img_content_pub">
                  <img v-lazy="item.url" alt="" />
                </div>
              </div>
            </div>
          </div>
          <!-- 如果需要分页器 -->
          <div class="swiper-pagination"></div>

          <!-- 如果需要导航按钮 -->
          <div
            v-if="workmanshipActive == 0 || (workmanshipActive != 0 && !reachStart1)"
            class="swiper-button-prev"
          ></div>
          <div
            v-else
            class="swiper_button_prev swiper_button_prev1"
            @click="changeDevActive3"
          ></div>
          <div v-if="!reachEnd1" class="swiper-button-next"></div>
          <div
            v-else
            class="swiper_button_next swiper_button_next1"
            @click="changeDevActive2"
          ></div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
//页面引入swiper
// import { swiper, swiperSlide } from "vue-awesome-swiper";
// import "swiper/swiper-bundle.css";
import $ from "jquery";
import Footer from "../components/footer";
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
import Header from "../components/header";
import { historyList } from "../utils/text1";
// import Footer from "../components/footer.vue";

export default {
  name: "GroupIntroduction",
  components: {
    // swiper,
    // swiperSlide,
    Header,
    Footer
  },
  data() {
    return {
      // workmanshipList:[3,2],
      workmanshipList: [
        [
          {
            url: require("../assets/imgs/gongyi_1.png"),
            title: "河南优质小麦",
            contentList: [
              "老家河南对制曲小麦有着高品质要求，只用河南优质小麦制作酒曲，至少60%的软质小麦，小麦硬度指数不高于45。老家河南专用优质小麦皮薄，软硬度非常适合酒业制曲，号称酒业制曲里最漂亮的美女，是用种子的标准精耕的酒用小麦。制曲小麦要求金黄色、颗粒坚实、饱满、均匀、皮薄等等，每一批小麦均需通过品种、色泽气味、容重、淀粉含量、水分含量、不完善粒、带壳粒、杂质等检测项目及霉变粒检验，通过感官、理化指标检验等层层把关。",
              "目前，老家河南的合作小麦基地主要分布在河南省周口市的西华县黄泛区、扶沟县，南阳市的邓州和唐河县，种植总面积达10万亩。",
            ],
          },
          {
            url: require("../assets/imgs/gongyi_2.jpg"),
            title: "红缨子糯高粱",
            contentList: [
              "贵州当地的红缨子糯高粱不仅颗粒坚实、粒小皮厚，耐蒸煮淀粉和单宁含量合理，而且对酿造大曲酱香型白酒大有益处的支链淀粉含量高达90%。唯有贵州当地的红缨子糯高粱才能经受“九次蒸煮”“八次发酵”“七取酒”的工艺折叠，历经多轮次蒸煮、翻烤，最后形成老家河南酒特殊的芳香化合物和多酚类物质，淬炼出老家河南酒酱香突出、优雅细腻、回味悠长的风味特征。%的软质小麦，小麦硬度指数不高于45。老家河南专用优质小麦皮薄，软硬度非常适合酒业制曲，号称酒业制曲里最漂亮的美女，是用种子的标准精耕的酒用小麦。制曲小麦要求金黄色、颗粒坚实、饱满、均匀、皮薄等等，每一批小麦均需通过品种、色泽气味、容重、淀粉含量、水分含量、不完善粒、带壳粒、杂质等检测项目及霉变粒检验，通过感官、理化指标检验等层层把关。",
              "老家河南酒业的合作高粱基地便选择在仁怀产区以及与仁怀相邻的周边地区（海拔、气候、土壤等自然条件接近的地块）建设，包括贵州省内遵义市桐梓县、遵义市红花岗区、遵义市凤冈县、遵义市绥阳县、安顺市、黔西南州安龙县等地。这些地方气候温暖湿润，土壤呈紫红色，土质松散、孔隙大，山地地形中砂质和砾土含量高，渗透性强，而且周围环境均无重工业污染，空气质量优良。经遵义市（仁怀市）酒业协会、红缨子高粱协会等专家联合考察认定，这些高粱产地是具备仁怀之后红缨子核心产区潜质的地方，在这样环境下种植出的高粱是酿造正宗酱香白酒最佳的原料。",
            ],
          },
          {
            url: require("../assets/imgs/gongyi_3.png"),
            title: "赤水河",
            contentList: [
              "赤水河，为中国长江上游支流，因河流含沙量高、水色赤黄而得名。赤水河全长444.5公里，流域面积2.04万平方公里。赤水河四分之三流域在大山中，是中国唯一一条没有被污染的长江支流。河水清澈透底，两岸陡峭，多险滩急流。赤水河流域，酿造业尤为发达，赤水河是中国最著名的“美酒河”，两岸民间自古酿酒。%。唯有贵州当地的红缨子糯高粱才能经受“九次蒸煮”“八次发酵”“七取酒”的工艺折叠，历经多轮次蒸煮、翻烤，最后形成老家河南酒特殊的芳香化合物和多酚类物质，淬炼出老家河南酒酱香突出、优雅细腻、回味悠长的风味特征。%的软质小麦，小麦硬度指数不高于45。老家河南专用优质小麦皮薄，软硬度非常适合酒业制曲，号称酒业制曲里最漂亮的美女，是用种子的标准精耕的酒用小麦。制曲小麦要求金黄色、颗粒坚实、饱满、均匀、皮薄等等，每一批小麦均需通过品种、色泽气味、容重、淀粉含量、水分含量、不完善粒、带壳粒、杂质等检测项目及霉变粒检验，通过感官、理化指标检验等层层把关。",
              "据汉文献载，公元前135年西汉年间赤水河就酿造出令汉武帝“甘美之”的赤水枸酱酒。赤水河因为其独特的地理环境和水文气候特性，酝酿了茅台、习酒、郎酒、老家河南等数十种美酒。根据当地地质部门的检测，赤水河含有多种微量元素，如钾、钙、镁、铁、硫、磷、锰、铜、锌、硒等，遵义地区地层的深井，与赤水河地下相通连，井水中也含有这些微量元素。",
            ],
          },
        ],
        [
          {
            url: require("../assets/imgs/gongyi_4.jpg"),
            title: "酱香",
            contentList: [
              "每一瓶好酒的悟道，都离不开巅峰技艺的造化。老家河南酒严格秉承茅台镇传统酿造之法，精选当地优质糯高粱，汲取赤水河之洞泉水，端午踩曲、重阳下沙，历经九次蒸煮、八次发酵、七次取酒、基酒窖藏等繁杂工艺，经名师盘勾、调勾、品勾，至少五年方成。",
            ],
          },
          {
            url: require("../assets/imgs/gongyi_5.jpg"),
            title: "传统酿造 铸就老家河南精粹品质",
            contentList: [
              "尊重传统酿造，所以严格秉承茅台镇传统酿造之法。茅台镇独特的水源、土壤、空气、气候与酿酒微生物生态环境在此浑然天成，以天地间独特的灵性，在一呼一吸间成就了茅台镇产区酱酒的独一无二。老家河南酒扎根茅台镇，精选地道糯高粱，汲取赤水河的洞泉水，重阳下沙，历经九次蒸煮，八次发酵，七次取酒，基酒窖藏等繁杂工艺，经名师盘勾、调勾、品勾，至少五年方能出品，确保了正宗酱香白酒的精粹品质。",
            ],
          },
        ],
      ],
      developmentList: historyList,
      active: 0,
      devActive: 0,
      workmanshipActive: 0,
      swiper: null,
      swiper1: null,
      reachEnd: false,
      reachStart: false,
      reachEnd1: false,
      reachStart1: false,
      goPre: false,
      goPre1: false,
    };
  },
  mounted() {
    this.initSwiperDev(0);
    this.initSwiper(0);

    // this.swiper1.slideTo(2, 1000, false)
    var data = this.$route.query.data;
    console.log(data);
    if (data) {
      this.jump(data);
    }
  },

  methods: {
    initSwiperDev(index) {
      var self = this;
      this.swiper = new Swiper(".development-swiper-"+index, {
        loop: false,
        // 如果需要分页器
        pagination: ".swiper-pagination",
        slidesPerView: 1,
        // 如果需要前进后退按钮
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
        // 如果需要滚动条
        // scrollbar: ".swiper-scrollbar",
        // observer 修改swiper子元素时自动初始化swiper
        observer: true,
        // observeParents 包括当前父元素的swiper发生变更时也会初始化swiper
        observeParents: true,
        onReachEnd: function () {
          // alert("到了最后一个slide");
          if (self.devActive != 2) {
            self.reachEnd = true;
            console.log(self.reachEnd);
          }
        },
        onReachBeginning: function () {
          self.reachStart = true;
          self.reachEnd = false;
          console.log("reachStart");
        },
        onSlideChangeStart: function (swiper) {
          console.log(swiper.activeIndex);
          var activeIndex = swiper.activeIndex;
          if(activeIndex == self.developmentList[self.devActive].length-2){
            self.reachEnd = false;
          }
          if (swiper.activeIndex == 1) {
            self.reachStart = false;
          }
        },
      });
      if (this.goPre) {
        this.swiper.slideTo(
          this.developmentList[this.devActive].length - 1,
          0,
          false
        );
        this.reachStart = false;
        // this.reachEnd = false;
      }
    },
    initSwiper(index) {
      var self = this;
      this.swiper1 = new Swiper(".brewing-technology-swiper-" + index, {
        loop: false,
        // 如果需要分页器
        pagination: ".swiper-pagination",
        slidesPerView: 1,
        // 如果需要前进后退按钮
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
        // 如果需要滚动条
        // scrollbar: ".swiper-scrollbar",
        // observer 修改swiper子元素时自动初始化swiper
        observer: true,
        // observeParents 包括当前父元素的swiper发生变更时也会初始化swiper
        observeParents: true,
        onReachEnd: function () {
          console.log("到了最后一个slide");
          if (self.workmanshipActive != 1) {
            self.reachEnd1 = true;
            console.log(self.reachEnd1);
          }
        },
        onReachBeginning: function () {
          self.reachStart1 = true;
          self.reachEnd1 = false;
          console.log("reachStart");
        },
        onSlideChangeStart: function (swiper1) {
          console.log(swiper1.activeIndex);
          var activeIndex = swiper1.activeIndex;
          if (swiper1.activeIndex == 1) {
            self.reachStart1 = false;
          }
          if(activeIndex == self.workmanshipList[self.workmanshipActive].length-2){
            self.reachEnd1 = false;
          }
        },
      });
      if (this.goPre1) {
        this.swiper1.slideTo(
          this.workmanshipList[this.workmanshipActive].length - 1,
          0,
          false
        );
        this.reachStart1 = false;
      }
    },
    jump(data) {
      this.$nextTick(() => {
        setTimeout(() => {
          //获取指定的div盒
          let targetbox;
          if (data == 0) {
            targetbox = $("#brand");
          } else if (data == 1) {
            targetbox = $("#devHistory");
          } else if (data == 2) {
            targetbox = $("#brewingTechnology");
          } else {
            console.log("targetbox");
          }

          var valOfScroll = targetbox.offset().top - 120;
          //让滚轴从当前位置的scroll在600毫秒内偏移到位置为valOfScroll。
          $("html,body").animate({ scrollTop: valOfScroll }, 600);
          // targetbox.scrollIntoView({
          //   behavior: "smooth", // 平滑过渡
          //   // block: "start", // 上边框与视窗顶部平齐。默认值
          // });
        });
      });
    },
    changeDevActive() {
      console.log("changeDevActive");
      // this.swiper.init();
      this.reachEnd = false;
      if (this.devActive < 2) {
        this.devActive++;
      }
      this.goPre = false;
      this.$nextTick(() => {
        this.initSwiperDev(this.devActive);
      });
    },
    changeDevActive1() {
      // this.swiper.init();
      console.log("changeDevActive1");
      if (this.devActive > 0) {
        this.devActive--;
      }
      this.goPre = true;
      this.$nextTick(() => {
        this.initSwiperDev(this.devActive);
      });
      this.reachStart = false;
    },
    changeDevActive2() {
      console.log(this.workmanshipActive);
      if (this.workmanshipActive < 1) {
        this.workmanshipActive++;
      }
      // this.swiper1.init();
      this.goPre1 = false;
      this.$nextTick(() => {
        this.initSwiper(this.workmanshipActive);
      });
      this.reachEnd1 = false;
      // console.log("changeDevActive2");
      // console.log(this.workmanshipActive);
    },
    changeDevActive3() {
      // this.swiper.init();
      if (this.workmanshipActive > 0) {
        this.workmanshipActive--;
      }
      // this.swiper1.slideTo(
      //   this.workmanshipList[this.workmanshipActive].length - 1,
      //   1000,
      //   false
      // );
      this.goPre1 = true;
      this.$nextTick(() => {
        this.initSwiper(this.workmanshipActive);
      });
      // this.$nextTick(() => {

      // });
      this.reachStart1 = false;
    },
    onChange(index) {
      this.active = index;
    },
    onChangeDev(index) {
      this.devActive = index;
      console.log(this.swiper);
      // this.swiper.init();
      this.$nextTick(() => {
        this.initSwiperDev(index);
      });
    },
    workmanshipChange(index) {
      this.workmanshipActive = index;
      // this.swiper1.init();
      this.$nextTick(() => {
        this.initSwiper(this.workmanshipActive);
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.group-introduction {
  .introduction {
    padding: 60px 0;
    width: 100%;
    background-image: url("../assets/introduction/introduction_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .introduction_main {
      width: 1200px;
      margin: 0 auto;
    }
    .title {
      margin-bottom: 40px;
      height: 54px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 70px;
        height: 26px;
      }
      span {
        font-size: 36px;
        color: #d6ac7d;
        margin: 0 10px;
      }
    }
    .detail {
      color: #666666;
      text-align: justify;
      font-size: 16px;
      line-height: 36px;
    }
    .btn_group {
      margin-top: 50px;
      margin-bottom: 40px;
      .btn {
        display: inline-block;
        width: 240px;
        height: 54px;
        text-align: center;
        line-height: 54px;
        background: #fffaf4;
        font-size: 20px;
        color: #333333;
        cursor: pointer;
      }
      .btn.active {
        color: #fff;
        background: #d6ac7d;
      }
      .btn:hover {
        color: #fff;
        background: #d6ac7d;
      }
    }
    .tab {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      height: 390px;
      .tab_img {
        width: 480px;
        height: 390px;
      }
      // img {
      //   object-fit: cover;
      // }
      .context {
        width: 640px;
        font-size: 16px;
        color: #666666;
        line-height: 34px;
        text-align: justify;
      }
    }
    .sub_title {
      font-size: 18px;
      color: #666666;
      font-weight: bold;
      text-align: left;
      margin-top: 80px;
    }
  }
  .development {
    padding: 30px 0;
    // background: linear-gradient(90deg, #ca9860 0%, #dcb881 100%);
    background: linear-gradient(90deg, #d0985a 0%, #dbb57d 99%);
    .dev_title {
      font-size: 32px;
      color: #fff;
      margin-bottom: 30px;
    }
    .dev_list {
      width: 100%;
      height: 260px;
      background: url("../assets/imgs/dev_bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      -webkit-background-size: cover;
      -o-background-size: cover;
      background-position: center;
      .dev_list_content {
        width: 1200px;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        .list_item {
          width: 400px;
          padding: 0 24px;
          cursor: pointer;
        }
        .list_item.line {
          border-left: 1px solid rgba(255, 255, 255, 0.2);
          border-right: 1px solid rgba(255, 255, 255, 0.2);
        }
        .list_item.active {
          background: rgba(255, 230, 182, 0.3);
        }
        .item_top,
        .item_bottom {
          height: 130px;
          // width: ;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          // padding: 0 24px;
        }
        .item_top {
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          // font-size: 72px;
          color: #ffffff;
          img {
            width: 86px;
            height: 104px;
          }
        }
        .item_bottom {
          color: #fff;
          .item_title {
            font-size: 32px;
          }
          .time {
            font-size: 24px;
          }
        }
      }
    }
    .time_line {
      display: flex;
      justify-content: center;
      align-items: center;
      .line_item {
        height: 20px;
        line-height: 20px;
        width: 400px;
        text-align: center;
        position: relative;
        top: -12px;
        .normal {
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 100%;
          background: #ffa23a;
          vertical-align: middle;
        }
        .active {
          width: 20px;
          height: 20px;
        }
        img {
          vertical-align: middle;
        }
      }
    }
    .brewing-technology-swiper,
    .development-swiper {
      width: 1200px;
      margin: auto;
      margin-top: 40px;
      overflow: hidden;
      position: relative;
      /deep/ .swiper-button-prev,
      .swiper-container-rtl .swiper-button-next {
        background-image: url("../assets/imgs/dev_arrow_left.png");
        background-size: 46px 46px;
        right: 80px;
        left: auto;
      }
      /deep/ .swiper-button-next,
      .swiper-container-rtl .swiper-button-prev {
        background-image: url("../assets/imgs/dev_arrow_right.png");
        background-size: 46px 46px;
      }
      /deep/ .swiper-button-next,
      .swiper-button-prev {
        width: 46px;
        height: 46px;
        top: auto;
        bottom: 0;
      }

      .swiper-wrapper {
        width: 1200px !important;
        margin: auto;
        .development_item {
          height: 500px;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          .dev_slide_img {
            width: 480px;
            height: 390px;
          }
          img {
            width: 480px;
            height: 390px;
            object-fit: cover;
          }
          .detail {
            margin-left: 50px;
            width: 670px;
            .sub_title {
              text-align: left;
              font-size: 24px;
              color: #fff;
              padding-bottom: 20px;
              border-bottom: 1px solid rgba(255, 255, 255, 0.5);
              margin-bottom: 20px;
            }
            .line {
              width: 160px;
              height: 3px;
              background: #ffffff;
              position: relative;
              top: -22px;
            }
            .content_text {
              font-size: 14px;
              color: #fff;
              line-height: 1.8;
              text-align: justify;
            }
          }
        }
      }
    }
    /deep/ .swiper-container-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      bottom: 0;
    }
    /deep/ .swiper-pagination-bullet {
      background: #d9b28b;
      opacity: 1;
    }
    /deep/ .swiper-pagination-bullet-active {
      background: none;
      background-image: url("../assets/imgs/dev_dot.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
  .workmanship {
    background: linear-gradient(90deg, #4db3be 2%, #27acbc 100%);
    .brewing-technology-swiper {
      /deep/ .swiper-button-prev,
      .swiper-container-rtl .swiper-button-next {
        background-image: url("../assets/imgs/dev_arrow_left.png");
        background-size: 46px 46px;
        left: 0;
        right: auto;
      }
      /deep/ .swiper-button-next,
      .swiper-container-rtl .swiper-button-prev {
        background-image: url("../assets/imgs/dev_arrow_right.png");
        background-size: 46px 46px;

        left: 80px;
        right: auto;
      }
      .swiper-wrapper {
        .development_item {
          .detail {
            margin-left: 0;
            margin-right: 50px;
          }
        }
      }
    }

    .dev_list {
      width: 100%;
      height: 98px;
      background: url("../assets/introduction/workmanship_bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      -webkit-background-size: cover;
      -o-background-size: cover;
      background-position: center;
      .time_line {
        .line_item {
          width: 450px;
        }
      }
    }
    .workmanship_tabs {
      display: flex;
      justify-content: center;
      align-items: center;
      .tab {
        width: 450px;
        height: 98px;
        font-size: 30px;
        color: #ffffff;
        text-align: center;
        line-height: 98px;
        cursor: pointer;
      }
      .tab.active {
        background: rgba(158, 246, 255, 0.3);
        color: #ffcd95;
      }
    }
    /deep/ .swiper-pagination-bullet {
      background: #6db9c1;
      opacity: 1;
    }
    /deep/ .swiper-pagination-bullet-active {
      background: none;
      background-image: url("../assets/introduction/acitve.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
  .swiper_button_prev,
  .swiper_button_next {
    width: 46px;
    height: 46px;
    top: auto;
    bottom: 0;
    background-image: url("../assets/imgs/dev_arrow_left.png");
    background-size: 46px 46px;
    right: 80px;
    left: auto;
    position: absolute;
    margin-top: -22px;
    z-index: 10;
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
  }
  .swiper_button_next {
    background-image: url("../assets/imgs/dev_arrow_right.png");
    right: 10px;
    left: auto;
  }

  .swiper_button_prev1 {
    left: 10px;
    right: auto;
  }
  .swiper_button_next1 {
    left: 80px;
    right: auto;
  }
}
</style>
